import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  APP_TAG,
  IT_TAG, LAB_TAG,
  MED_INNOVATIONS_TAG,
  PSYCHOLOGY_TAG, TECH_TAG, ARTIFICIAL_INTELLIGENCE_TAG,
  NATURAL_MEDICINE_TAG, VR_TAG, WEB_SERVICES_TAG, ECOLOGY_TAG,
  GAMIFICATION_TAG, VISUALISATION_TAG, DIAGNOSTIC_TAG,
  DERMATOLOGY_TAG, COSMETIC_SURGERY_TAG, CARER_TAG,
  BREATHING_TAG, SPEECH_THERAPY_TAG, SUSTAINABLE_TAG, MARKETPLACE_TAG
} from './tags';

const PROJECTS_2022 = [
  {
    name: 'play.air',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/play.air_infografika.png"
        alt="Projekt: play.air"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/play.air_infografika_eng.png"
        alt="Projekt: play.air"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Marcin Cackowski <span className="bullet">•</span> Piotr Strus</p>
        <p>Natalia Szczucka <span className="bullet">•</span> Łukasz Zaręba</p>
      </>
    ),
    authorsFor2024: (
      <>
        <div>Marcin Cackowski, Piotr Strus, Natalia Szczucka, Łukasz Zaręba</div>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-1-position',
    mentors: (
      <p>Ireneusz Martyniuk<span className="bullet">•</span> Invento Capital</p>
    ),
    tags: [GAMIFICATION_TAG, BREATHING_TAG, SPEECH_THERAPY_TAG],
    description: 'previous-editions.project-2022-1-description',
    trl_level: 'previous-editions.project-2022-1-trl_level',
    advantages_1: 'previous-editions.project-2022-1-advantages_1',
    advantages_2: 'previous-editions.project-2022-1-advantages_2',
    advantages_3: 'previous-editions.project-2022-1-advantages_3',
    applications_1: 'previous-editions.project-2022-1-applications_1',
    applications_2: 'previous-editions.project-2022-1-applications_2',
    applications_3: 'previous-editions.project-2022-1-applications_3',
  },
  {
    name: 'BODI',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/BODI_infografika.png"
        alt="Projekt: BODI"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/BODI_infografika_eng.png"
        alt="Projekt: BODI"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Gabriela Kaczmarek <span className="bullet">•</span> Sara Kamrowska</p>
        <p>Mikołaj Pinkosz</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-2-position',
    mentors: (
      <p>Robert Stachurski<span className="bullet">•</span> GlucoActive</p>
    ),
    tags: [WEB_SERVICES_TAG, COSMETIC_SURGERY_TAG, VISUALISATION_TAG],
    description: 'previous-editions.project-2022-2-description',
    trl_level: 'previous-editions.project-2022-2-trl_level',
    advantages_1: 'previous-editions.project-2022-2-advantages_1',
    advantages_2: 'previous-editions.project-2022-2-advantages_2',
    advantages_3: 'previous-editions.project-2022-2-advantages_3',
    applications_1: 'previous-editions.project-2022-2-applications_1',
    applications_2: 'previous-editions.project-2022-2-applications_2',
    applications_3: 'previous-editions.project-2022-2-applications_3',
  },
  {
    name: 'Labeo',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Labeo_infografika.jpg"
        alt="Projekt: Labeo"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/Labeo_infografika_eng .png"
        alt="Projekt: Labeo"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Stanisław Anczyk <span className="bullet">•</span> Wiktor Bednarski</p>
        <p>Urszula Stachowiak</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-3-position',
    mentors: (
      <p>Dawid Zieliński <span className="bullet">•</span> Columbus Energy</p>
    ),
    tags: [WEB_SERVICES_TAG, LAB_TAG, IT_TAG],
    description: 'previous-editions.project-2022-3-description',
    trl_level: 'previous-editions.project-2022-3-trl_level',
    advantages_1: 'previous-editions.project-2022-3-advantages_1',
    advantages_2: 'previous-editions.project-2022-3-advantages_2',
    advantages_3: 'previous-editions.project-2022-3-advantages_3',
    applications_1: 'previous-editions.project-2022-3-applications_1',
    applications_2: 'previous-editions.project-2022-3-applications_2',
    applications_3: 'previous-editions.project-2022-3-applications_3',
  },
  {
    name: 'EcoPot',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/ecopot_infografika.png"
        alt="Projekt: EcoPot"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/ecopot_infografika_eng.png"
        alt="Projekt: EcoPot"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Kamila Kotnis <span className="bullet">•</span> Maksymilian Czekaj</p>
        <p>Adrian Pawełkowski </p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-9-position',
    mentors: (
      <p>Jerzy Szewczyk<span className="bullet">•</span> PRO-Plus</p>
    ),
    tags: [ECOLOGY_TAG, NATURAL_MEDICINE_TAG, MARKETPLACE_TAG],
    description: 'previous-editions.project-2022-9-description',
    trl_level: 'previous-editions.project-2022-9-trl_level',
    advantages_1: 'previous-editions.project-2022-9-advantages_1',
    advantages_2: 'previous-editions.project-2022-9-advantages_2',
    advantages_3: 'previous-editions.project-2022-9-advantages_3',
    applications_1: 'previous-editions.project-2022-9-applications_1',
    applications_2: 'previous-editions.project-2022-9-applications_2',
    applications_3: 'previous-editions.project-2022-9-applications_3',
  },
  {
    name: 'METGrass',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/METGrass_infografika.png"
        alt="Projekt: METGrass"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/METGrass_infografika_eng.png"
        alt="Projekt: METGrass"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Patryk Rząsa  <span className="bullet">•</span> Karol Sadowski</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-4-position',
    mentors: (
      <p>Dawid Zieliński <span className="bullet">•</span> Columbus Energy</p>
    ),
    tags: [TECH_TAG, ECOLOGY_TAG, SUSTAINABLE_TAG],
    description: 'previous-editions.project-2022-4-description',
    trl_level: 'previous-editions.project-2022-4-trl_level',
    advantages_1: 'previous-editions.project-2022-4-advantages_1',
    advantages_2: 'previous-editions.project-2022-4-advantages_2',
    advantages_3: 'previous-editions.project-2022-4-advantages_3',
    applications_1: 'previous-editions.project-2022-4-applications_1',
    applications_2: 'previous-editions.project-2022-4-applications_2',
    applications_3: 'previous-editions.project-2022-4-applications_3',
  },
  {
    name: 'IMAGINARY YOU',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/IMAGINARY YOU_infografika.png"
        alt="Projekt: IMAGINARY YOU"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/IMAGINARY YOU_infografika_eng.png"
        alt="Projekt: IMAGINARY YOU"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Katarzyna Kaczyńska <span className="bullet">•</span> Oliwia Andrejko</p>
        <p>Tomasz Maroszczuk</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-5-position',
    mentors: (
      <p>Piotr Mirosz <span className="bullet">•</span> ACE Agency</p>
    ),
    tags: [MED_INNOVATIONS_TAG, VR_TAG, PSYCHOLOGY_TAG],
    description: 'previous-editions.project-2022-5-description',
    trl_level: 'previous-editions.project-2022-5-trl_level',
    advantages_1: 'previous-editions.project-2022-5-advantages_1',
    advantages_2: 'previous-editions.project-2022-5-advantages_2',
    advantages_3: 'previous-editions.project-2022-5-advantages_3',
    applications_1: 'previous-editions.project-2022-5-applications_1',
    applications_2: 'previous-editions.project-2022-5-applications_2',
    applications_3: 'previous-editions.project-2022-5-applications_3',
  },
  {
    name: 'Calmy',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Calmy_infografika.png"
        alt="Projekt: Calmy"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/Calmy_infografika_eng.png"
        alt="Projekt: Calmy"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Joanna Tyrchniewicz <span className="bullet">•</span> Kacper Łuszczki</p>
        <p>Jędrzej Dragan <span className="bullet">•</span> Mateusz Szymański</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-7-position',
    mentors: (
      <p>Artur Chajewski <span className="bullet">•</span> Perlan Technologies</p>
    ),
    tags: [MED_INNOVATIONS_TAG, PSYCHOLOGY_TAG, APP_TAG],
    description: 'previous-editions.project-2022-7-description',
    trl_level: 'previous-editions.project-2022-7-trl_level',
    advantages_1: 'previous-editions.project-2022-7-advantages_1',
    advantages_2: 'previous-editions.project-2022-7-advantages_2',
    advantages_3: 'previous-editions.project-2022-7-advantages_3',
    applications_1: 'previous-editions.project-2022-7-applications_1',
    applications_2: 'previous-editions.project-2022-7-applications_2',
    applications_3: 'previous-editions.project-2022-7-applications_3',
  },
  {
    name: 'Project Niania',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/ProjectNiania_infografika.png"
        alt="Projekt: Project Niania"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/ProjectNiania_infografika_eng.png"
        alt="Projekt: Project Niania"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Kshitij Wadhwani <span className="bullet">•</span> Magdalena Ostaszewska</p>
        <p>Karolina Zamarripa <span className="bullet">•</span> Monika Wyżlic</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-8-position',
    mentors: (
      <p>Arkadiusz Cempura<span className="bullet">•</span> INNERGO Systems</p>
    ),
    tags: [IT_TAG, APP_TAG, CARER_TAG],
    description: 'previous-editions.project-2022-8-description',
    trl_level: 'previous-editions.project-2022-8-trl_level',
    advantages_1: 'previous-editions.project-2022-8-advantages_1',
    advantages_2: 'previous-editions.project-2022-8-advantages_2',
    advantages_3: 'previous-editions.project-2022-8-advantages_3',
    applications_1: 'previous-editions.project-2022-8-applications_1',
    applications_2: 'previous-editions.project-2022-8-applications_2',
    applications_3: 'previous-editions.project-2022-8-applications_3',
  },
  {
    name: 'Alter Risk',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/AlterRisk_infografika.png"
        alt="Projekt: Alter Risk"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/AlterRisk_infografika_eng.png"
        alt="Projekt: Alter Risk"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Mateusz Mazurek <span className="bullet">•</span> Leszek Lammel</p>
        <p>Wiktoria Chciuk</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-6-position',
    mentors: (
      <p>Kamil Więcej <span className="bullet">•</span> Health IT sp. z o. o.</p>
    ),
    tags: [ARTIFICIAL_INTELLIGENCE_TAG, MED_INNOVATIONS_TAG, DIAGNOSTIC_TAG],
    description: 'previous-editions.project-2022-6-description',
    trl_level: 'previous-editions.project-2022-6-trl_level',
    advantages_1: 'previous-editions.project-2022-6-advantages_1',
    advantages_2: 'previous-editions.project-2022-6-advantages_2',
    advantages_3: 'previous-editions.project-2022-6-advantages_3',
    applications_1: 'previous-editions.project-2022-6-applications_1',
    applications_2: 'previous-editions.project-2022-6-applications_2',
    applications_3: 'previous-editions.project-2022-6-applications_3',
  },
  {
    name: 'Dermatosu',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Dermatosu_infografika.png"
        alt="Projekt: Dermatosu"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/Dermatosu_infografika_eng.png"
        alt="Projekt: Dermatosu"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Jan Jankowski <span className="bullet">•</span> Agata Andrzejczyk</p>
        <p>Jan Żuromski <span className="bullet">•</span> Aleksandra Barwicka</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-10-position',
    mentors: (
      <p>Ligia Kornowska<span className="bullet">•</span> Polska Federacja Szpitali</p>
    ),
    tags: [ARTIFICIAL_INTELLIGENCE_TAG, DERMATOLOGY_TAG, MED_INNOVATIONS_TAG],
    description: 'previous-editions.project-2022-10-description',
    trl_level: 'previous-editions.project-2022-10-trl_level',
    advantages_1: 'previous-editions.project-2022-10-advantages_1',
    advantages_2: 'previous-editions.project-2022-10-advantages_2',
    advantages_3: 'previous-editions.project-2022-10-advantages_3',
    applications_1: 'previous-editions.project-2022-10-applications_1',
    applications_2: 'previous-editions.project-2022-10-applications_2',
    applications_3: 'previous-editions.project-2022-10-applications_3',
  },
]

export default PROJECTS_2022;


