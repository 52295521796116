import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  APP_TAG,
  COVID_TAG,
  FERTILITY_TAG, GENETICS_TAG,
  HEART_DISEASE_TAG,
  IT_TAG, LAB_TAG,
  MED_INNOVATIONS_TAG,
  PSYCHOLOGY_TAG, SIGHT_TAG, TECH_TAG, WELL_BEING_TAG
} from './tags';

const PROJECTS_2021 = [
  {
    name: 'R-flow',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_R-flow.jpg"
        alt="Projekt: R-flow"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Adrianna Pielech <span className="bullet">•</span> Karol Gałan</p>
        <p>Kamil Kozłowski <span className="bullet">•</span> Paweł Turowski</p>
      </>
    ),
    authorsFor2024: (
      <>
        <div>Paweł Turowski, Karol Gałan, Adrianna Pielech, Kamil Kozłowski</div>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-1-position',
    mentors: (
      <p>Ireneusz Martyniuk<span className="bullet">•</span> Invento Capital</p>
    ),
    tags: [IT_TAG, MED_INNOVATIONS_TAG, COVID_TAG],
    description: 'previous-editions.project-2021-1-description',
    trl_level: 'previous-editions.project-2021-1-trl_level',
    advantages_1: 'previous-editions.project-2021-1-advantages_1',
    advantages_2: 'previous-editions.project-2021-1-advantages_2',
    applications_1: 'previous-editions.project-2021-1-applications_1',
    applications_2: 'previous-editions.project-2021-1-applications_2',
    applications_3: 'previous-editions.project-2021-1-applications_3',
  },
  {
    name: 'EyeQual',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_EyeQual.jpg"
        alt="Projekt: EyeQual"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Maciej Nowaczyk <span className="bullet">•</span> Gabriela Piątek</p>
        <p>Bartosz Różański <span className="bullet">•</span> Marika Partyka</p>
      </>
    ),
    authorsFor2024: (
      <>
        <div>Maciej Nowaczyk, Gabriela Piątek, Bartosz Różański, Marika Partyka</div>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-6-position',
    mentors: (
      <p>Jakub Grabski <span className="bullet">•</span> Jutro Medical</p>
    ),
    tags: [IT_TAG, SIGHT_TAG, TECH_TAG],
    description: 'previous-editions.project-2021-6-description',
    trl_level: 'previous-editions.project-2021-6-trl_level',
    advantages_1: 'previous-editions.project-2021-6-advantages_1',
    advantages_2: 'previous-editions.project-2021-6-advantages_2',
    applications_1: 'previous-editions.project-2021-6-applications_1',
    applications_2: 'previous-editions.project-2021-6-applications_2',
    applications_3: 'previous-editions.project-2021-6-applications_3',
  },
  {
    name: 'MindTherapy',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_MindTherapy.jpg"
        alt="Projekt: MindTherapy"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Katarzyna Kaczyńska <span className="bullet">•</span> Michał Piwoński</p>
        <p>Joanna Frankiewicz <span className="bullet">•</span> Hanna Dzemidzenka</p>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-3-position',
    mentors: (
      <p>Natalia Suska <span className="bullet">•</span> Neurohm</p>
    ),
    tags: [IT_TAG, APP_TAG, PSYCHOLOGY_TAG],
    description: 'previous-editions.project-2021-3-description',
    trl_level: 'previous-editions.project-2021-3-trl_level',
    advantages_1: 'previous-editions.project-2021-3-advantages_1',
    advantages_2: 'previous-editions.project-2021-3-advantages_2',
    applications_1: 'previous-editions.project-2021-3-applications_1',
    applications_2: 'previous-editions.project-2021-3-applications_2',
    applications_3: 'previous-editions.project-2021-3-applications_3',
  },
  {
    name: 'Lifesnip',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_Lifesnip.jpg"
        alt="Projekt: Lifesnip"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Marcin Bielicki <span className="bullet">•</span> Filip Przybylski</p>
        <p>Łukasz Nowotka <span className="bullet">•</span> Zuzanna Kosobudzka</p>
      </>
    ),
    authorsFor2024: (
      <>
        <div>Marcin Bielicki, Filip Przybylski, Łukasz Nowotka, Zuzanna Kosobudzka</div>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-7-position',
    mentors: (
      <p>Piotr Jakieła <span className="bullet">•</span> Perlan Technologies</p>
    ),
    tags: [GENETICS_TAG, MED_INNOVATIONS_TAG, TECH_TAG],
    description: 'previous-editions.project-2021-7-description',
    trl_level: 'previous-editions.project-2021-7-trl_level',
    advantages_1: 'previous-editions.project-2021-7-advantages_1',
    advantages_2: 'previous-editions.project-2021-7-advantages_2',
    applications_1: 'previous-editions.project-2021-7-applications_1',
    applications_2: 'previous-editions.project-2021-7-applications_2',
    applications_3: 'previous-editions.project-2021-7-applications_3',
  },
  {
    name: 'My - Płodni!',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_My-Płodni!.jpg"
        alt="Projekt: My - Płodni!"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Magdalena Włodarczyk <span className="bullet">•</span> Barbara Papiż</p>
        <p>Monika Muszak <span className="bullet">•</span> Klaudia Żak</p>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-4-position',
    mentors: (
      <p>Małgorzata Kęsik-Brodacka <span className="bullet">•</span> NIL</p>
    ),
    tags: [FERTILITY_TAG, LAB_TAG, TECH_TAG],
    description: 'previous-editions.project-2021-4-description',
    trl_level: 'previous-editions.project-2021-4-trl_level',
    advantages_1: 'previous-editions.project-2021-4-advantages_1',
    advantages_2: 'previous-editions.project-2021-4-advantages_2',
    applications_1: 'previous-editions.project-2021-4-applications_1',
    applications_2: 'previous-editions.project-2021-4-applications_2',
    applications_3: 'previous-editions.project-2021-4-applications_3',
  },
  {
    name: 'Neuroduvet',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_Neuroduvet.jpg"
        alt="Projekt: Neuroduvet"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Karol Pierzchała <span className="bullet">•</span> Tomasz Necio</p>
        <p>Julia Machowiec <span className="bullet">•</span> Zuzanna Lewandowska</p>
        <p>Marta Agnieszczak </p>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-5-position',
    mentors: (
      <p>Jerzy Szewczyk <span className="bullet">•</span> Pro-PLUS</p>
    ),
    tags: [WELL_BEING_TAG, APP_TAG, TECH_TAG],
    description: 'previous-editions.project-2021-5-description',
    trl_level: 'previous-editions.project-2021-5-trl_level',
    advantages_1: 'previous-editions.project-2021-5-advantages_1',
    advantages_2: 'previous-editions.project-2021-5-advantages_2',
    applications_1: 'previous-editions.project-2021-5-applications_1',
    applications_2: 'previous-editions.project-2021-5-applications_2',
    applications_3: 'previous-editions.project-2021-5-applications_3',
  },
  {
    name: 'Protecore',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_Protecore.jpg"
        alt="Projekt: Protecore"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Hubert Szymski <span className="bullet">•</span> Maria Piecha</p>
        <p>Jakub Klecha</p>
      </>
    ),
    authorsFor2024: (
      <>
        <div>Hubert Szymski, Maria Piecha, Jakub Klecha</div>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-2-position',
    mentors: (
      <p>Marcin Grudniewski & Christopher Kaplan <span className="bullet">•</span> Axcend Health</p>
    ),
    tags: [IT_TAG, APP_TAG, HEART_DISEASE_TAG],
    description: 'previous-editions.project-2021-2-description',
    trl_level: 'previous-editions.project-2021-2-trl_level',
    advantages_1: 'previous-editions.project-2021-2-advantages_1',
    advantages_2: 'previous-editions.project-2021-2-advantages_2',
    applications_1: 'previous-editions.project-2021-2-applications_1',
    applications_2: 'previous-editions.project-2021-2-applications_2',
    applications_3: 'previous-editions.project-2021-2-applications_3',
  },
]

export default PROJECTS_2021;


